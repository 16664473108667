import { useUserStore } from 'store/user.store';
import { AuthManagerService } from 'services/auth/authManager.service';

export function useSetClientUserInfo(): void {
  const userStore = useUserStore();

  onBeforeMount(async () => {
    if (userStore.isUserLoggedIn) {
      AuthManagerService.setSentryUser();
      AuthManagerService.startCheckBasis();
      await AuthManagerService.loadBasketCount();
      return;
    }

    try {
      const isAuth = await AuthManagerService.checkAuth();
      if (!isAuth) {
        return;
      }

      const userInfo = await AuthManagerService.setUserInfo();
      if (!userInfo) {
        return;
      }

      if (basesManager && userInfo.baseToSelect) {
        basesManager.currentUserBase = userInfo.baseToSelect;
      }
      // eslint-disable-next-line no-empty
    } catch (error) { }
  });
}
