import { SsoAuthManagerService } from 'services/auth/ssoAuthManager.service';
import { H3Event, sendRedirect, getCookie } from 'h3';
import { RequestHelper } from 'shared/utils/requestHelper.util';

export async function useDomainAuth(): Promise<boolean> {
  if (process.server) {
    const event = useRequestEvent();
    const { public: { refreshTokenName, appHost } } = useRuntimeConfig() || { public: {} };
    const refreshToken = getCookie(event, refreshTokenName);
    const cleanSubdomain = RequestHelper.getSubdomain(event, appHost);
    const {
      code, codeVerifier, redirectUrl,
    } = SsoAuthManagerService.getValuesAfterLoginRedirect(event);

    if (cleanSubdomain &&
      !refreshToken &&
      appHost &&
      (!code ||
        !codeVerifier ||
        !redirectUrl)
    ) {
      return startSsoAuth(event, RequestHelper.getRequestURL(event).href, cleanSubdomain, appHost);
    }
  }

  async function startSsoAuth(event: H3Event, successRedirectUrl: string, provider: string, appHost: string): Promise<boolean> {
    try {
      const { redirectUrl } = (await SsoAuthManagerService.startServerSsoAuth(event, successRedirectUrl, provider.toUpperCase())) || {};
      redirectUrl && await sendRedirect(event, redirectUrl, 302);
      return true;
    } catch (error) {
      console.error(`SAML redirect error: ${error?.message}`);
      const requestUrl = RequestHelper.getRequestURL(event);
      requestUrl.host = appHost;
      requestUrl.pathname = '';
      requestUrl.search = '';
      requestUrl.hash = '';
      requestUrl.port = '';
      await sendRedirect(event, requestUrl.toString(), 302);
      return false;
    }
  }

  return false;
}
